exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-books-tsx": () => import("./../../../src/pages/books.tsx" /* webpackChunkName: "component---src-pages-books-tsx" */),
  "component---src-pages-climbing-tsx": () => import("./../../../src/pages/climbing.tsx" /* webpackChunkName: "component---src-pages-climbing-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-programming-tsx": () => import("./../../../src/pages/programming.tsx" /* webpackChunkName: "component---src-pages-programming-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-joys-of-apex-index-tsx": () => import("./../../../src/templates/joys-of-apex-index.tsx" /* webpackChunkName: "component---src-templates-joys-of-apex-index-tsx" */)
}

